import { EventEmitter } from 'events';
import { useRouter } from 'next/router';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';

const EventsContext = createContext<EventEmitter>( null );
EventsContext.displayName = 'Events';

const DEFAULT_EMIT_TIMEOUT = 3000; // 3 seconds

export default function EventsProvider( { children } ) {
	const event = useRef( new EventEmitter() );
	const lastEmitTime = useRef( new Map() );
	const router = useRouter();
	const pathnameRef = useRef( router.pathname );
	pathnameRef.current = router.pathname;
	
	const socketToEvent = useCallback( ( socketEvent ) => {
		if ( pathnameRef.current.includes( '[id]/edit' ) ) return; // disable socket events on edit pages
		
		const currentTime = Date.now();
		let message = socketEvent.data;
		let data = undefined;
		if ( message.startsWith( 'JSON_' ) ) {
			const { topic, ...rest } = JSON.parse( message.slice( 5 ) );
			message = topic;
			data = rest;
		}
		const lastTime = lastEmitTime.current.get( message ) || 0;
		
		if ( currentTime - lastTime < DEFAULT_EMIT_TIMEOUT ) {
			// console.debug( 'socketToEvent: skipping emit', message );
			return;
		}
		
		event.current.emit( message, data );
		lastEmitTime.current.set( message, currentTime );
	}, [] );
	
	useEffect( () => {
		const win = window as any;
		if ( !win.rws ) {
			console.error( 'window.rws not initialized' );
			return;
		}
		win.rws.addEventListener( 'message', socketToEvent );
		
		return () => {
			win.rws?.removeEventListener( 'message', socketToEvent );
		};
	}, [] );
	
	return (
		<EventsContext.Provider value={event.current}>
			{children}
		</EventsContext.Provider>
	);
}

export function useEvents() {
	return useContext( EventsContext );
}

export function withEvents( Component ) {
	return ( props ) => (
		<EventsContext.Consumer>
			{( event ) => <Component event={event} {...props}/>}
		</EventsContext.Consumer>
	);
}
