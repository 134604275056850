import { gql } from '@apollo/client';

export const StaffTableWriteAllDataGQL = gql`
	mutation StaffTableWrite_Data($input: StaffTableValidator!) {
		staffTableWrite(input: $input) {
			id
			actionsData
			selectedTabsData
		}
	}
`;
