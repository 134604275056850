import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useMenu } from '@/providers/menu';
import { getBrowserTimezone } from '@/utils/timezone';
import { urlSearchParams } from '@/utils/urlSearchParams';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Link, MenuItem } from '@mui/material';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { Fragment, useRef, useState } from 'react';
import { TableInstance } from 'react-table';
import { ExportEstimatesCSV } from '../../../data/commerce/estimate.graphql';
import { ExportInvoicesCSV } from '../../../data/commerce/invoice.graphql';
import { ExportOrdersCSV } from '../../../data/commerce/order.graphql';
import csvExport from '../../../helpers/csvExport';

export default function GraphqlTableCSVButton( {
	tableActionMenu,
	variables,
	table,
}: {
	tableActionMenu: string[],
	variables: any,
	table?: TableInstance
	
} ) {
	const { staff } = useUserInfo();
	const router = useRouter();
	const { enqueueSnackbar } = useSnackbar();
	const { showMenu } = useMenu();
	const linkRef = useRef( null );
	const [ loading, setLoading ] = useState( false );
	
	return (
		<Fragment>
			<Link ref={linkRef} sx={{ display: 'none' }}>CSV Link</Link>
			<AsyncLoadingButton
				loading={loading}
				disabled={loading}
				variant='outlined'
				endIcon={<ExpandMoreIcon/>}
				onClick={( e ) => showMenu( ( { closeMenu } ) => (
					<Fragment>
						{tableActionMenu.slice( 1 ).map( ( action, index ) => (
							<MenuItem
								key={index}
								disabled={loading}
								onClick={async () => {
									try {
										setLoading( true );
										if ( action.includes( 'CSV' ) ) {
											enqueueSnackbar( 'Downloading CSV...', { variant: 'info' } );
											const state = router.query.s
												? JSON.parse( atob( decodeURIComponent( router.query.s as string ) ) )
												: undefined;
											
											let csvQuery;
											const timezone: string = getBrowserTimezone();
											switch ( action ) {
												case 'invoicesCSV':
													csvQuery = ExportInvoicesCSV;
													break;
												case 'estimatesCSV':
													csvQuery = ExportEstimatesCSV;
													break;
												default:
													csvQuery = ExportOrdersCSV;
											}
											
											const { data, filename } = await csvExport(
												state,
												csvQuery,
												action.substring( 0, action.length - 3 ),
												timezone,
												undefined,
												table,
											);
											
											if ( data?.invoiceCSVString || data?.estimateCSVString || data?.orderCSVString ) {
												const blob = new Blob( [ data?.invoiceCSVString || data?.estimateCSVString || data?.orderCSVString ], { type: 'text/csv;charset=utf-8;' } );
												const url = URL.createObjectURL( blob );
												const fixedEncodedURI = url.replace( /#/g, '%23' );
												linkRef.current.setAttribute( 'href', fixedEncodedURI );
												linkRef.current.setAttribute( 'download', filename );
												linkRef.current.click();
											}
										} else {
											const pdfFilters = Buffer.from( JSON.stringify( variables ) ).toString( 'base64' );
											if ( pdfFilters ) {
												window.open(
													`/api/preview/pdf/${staff?.company.id}/${action}?${urlSearchParams( {
														timezone: getBrowserTimezone(),
														s       : pdfFilters,
													} )}`,
													'_blank',
												);
											}
										}
										closeMenu();
										setLoading( false );
									} catch ( error ) {
										console.error( error );
									} finally {
										setLoading( false );
									}
								}}>
								{action.slice( -3 )}
							</MenuItem>
						) )}
					</Fragment>
				), e.currentTarget, {
					anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
					transformOrigin: { vertical: 'top', horizontal: 'center' },
				} )}>
				{tableActionMenu[ 0 ]}
			</AsyncLoadingButton>
		</Fragment>
	);
}

