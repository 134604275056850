import useEventEffect from '@/hooks/useEventEffect';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useMenu } from '@/providers/menu';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	Collapse,
	Divider,
	Fade,
	Grid,
	Paper,
	Stack,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import React, { Dispatch, Fragment, isValidElement, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TableInstance } from 'react-table';
import Actions from '../../actions';
import Filters from '../filters';
import { ActionType, HeaderExtraActionsType, QuickActionsType } from '../index';
import TableSearchBar from '../tableSearchBar';
import ColumnsButton from './columnsButton';
import GraphqlTableCSVButton from './csvButton';
import QuickEditSaveButton, { QuickEditCancelButton } from './quickEditSaveButton';
import QuickFiltersButton from './quickFiltersButton';
import SavedFiltersButton from './savedFiltersButton';

export type GraphqlTableSavableData = { table: string, data: any[] };

export default function GraphqlTableHeader( {
	table,
	count,
	badgeCount,
	searchable,
	renderMultiActions,
	tableSavedFilters,
	useQuickFilters,
	hiddenTableColumns,
	tableActionMenu,
	variables,
	refreshable,
	hideColumnFilters,
	headerExtraActions,
	selectedRows,
	showMobileCheckbox,
	setShowMobileCheckbox,
}: {
	table: TableInstance,
	count: number,
	badgeCount: number,
	searchable?: boolean,
	tableSavedFilters?: string[],
	useQuickFilters?: () => QuickActionsType[],
	hiddenTableColumns?: string,
	refreshable?: boolean,
	tableActionMenu?: string[],
	variables?: any,
	hideColumnFilters?: boolean,
	renderMultiActions?: ( rows: string[], clearRows: () => void, selectedFlatRows?: any[] ) => ReactNode,
	headerExtraActions?: HeaderExtraActionsType,
	selectedRows?: any[],
	showMobileCheckbox?: boolean,
	setShowMobileCheckbox?: Dispatch<SetStateAction<boolean>>
} ) {
	const { t } = useTranslation();
	const { showMenu } = useMenu();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const { staff } = useUserInfo();
	const { isCloverDevice } = useGetDeviceInfo();
	const event = useEvents();
	
	const [ tableEditableData, setTableEditableData ] = useState<GraphqlTableSavableData>( null );
	const [ tableSavableData, setTableSavableData ] = useState<GraphqlTableSavableData>( { table: '', data: [] } );
	
	const totalSelectedFlatRows = table.selectedFlatRows.map( ( row ) => row.original );
	const totalSelectedRowIds = Object.keys( table.state.selectedRowIds );
	const totalSelectedRowsLength = totalSelectedRowIds?.length;
	const filteredColumns = table.allColumns.filter( ( column ) => column.canFilter );
	
	const alwaysShowActionMenu = tableActionMenu?.includes( 'showAlways' );
	
	useEventEffect( event, 'quick.edit', ( editableData ) => setTableEditableData( editableData ), [] );
	useEventEffect( event, 'quick.save', ( savableData ) => setTableSavableData( savableData ), [] );
	
	return (
		<Grid container>
			<Grid item xs={12}>
				<Stack direction='row' alignItems='center' spacing={1} sx={{ mt: 1 }}>
					<Collapse in={searchable} sx={{ width: '100%' }}>
						<TableSearchBar
							globalFilter={table.state.globalFilter}
							setGlobalFilter={table.setGlobalFilter}
						/>
					</Collapse>
					{isMobile && renderMultiActions && (
						<Stack direction='row' alignItems='center' spacing={1} sx={{ maxWidth: 200 }}>
							{showMobileCheckbox && (
								<Button
									variant='outlined'
									onClick={() => {
										if ( table.selectedFlatRows?.length ) {
											table.toggleAllRowsSelected( false );
										} else {
											table.toggleAllRowsSelected();
										}
									}}>
									{table.selectedFlatRows?.length ? 'Deselect All' : 'Select All'}
								</Button>
							)}
							<Button
								variant='outlined'
								onClick={() => {
									if ( showMobileCheckbox ) {
										table.toggleAllRowsSelected( false );
									}
									setShowMobileCheckbox?.( !showMobileCheckbox );
								}}>
								{showMobileCheckbox ? 'Cancel' : 'Select multiple'}
							</Button>
						</Stack>
					)}
				</Stack>
			</Grid>
			<Grid item xs={6} my={1}>
				<Fade in={count > 0}>
					<Stack justifyContent={isMobile ? 'space-between' : undefined} spacing={1}>
						<Stack
							direction='row'
							component={Paper}
							sx={{
								p         : 1,
								border    : 0,
								alignItems: 'center',
								alignSelf : 'start',
							}}>
							{count && (
								<Typography sx={{ whiteSpace: 'nowrap' }}>
									{t( 'common:total' )}: {count}
								</Typography>
							)}
							{Boolean( totalSelectedRowIds.length ) && Boolean( renderMultiActions ) && (
								<Stack direction='row' sx={{ whiteSpace: 'nowrap' }}>
									<Divider flexItem orientation='vertical' sx={{ bgcolor: 'divider', mx: 1 }}/>
									<Typography sx={{ fontWeight: '500' }}>
										{t( 'common:selected' )}: {totalSelectedRowsLength > 100
											? totalSelectedRowsLength
											: totalSelectedRowsLength || 0}
									</Typography>
								
								</Stack>
							)}
							{Boolean( totalSelectedRowIds.length ) && Boolean( renderMultiActions )
								&& (
									<Button
										sx={{ ml: 1 }}
										variant='text'
										color='error'
										onClick={() => {
											table.toggleAllRowsSelected( false );
											setShowMobileCheckbox?.( false );
										}}>Deselect all
									</Button>
								)}
						</Stack>
						<Collapse in={Boolean( renderMultiActions && totalSelectedRowIds.length )}>
							<Box sx={{ '.MuiButton-root': { width: 'fit-content' } }}>
								{renderMultiActions?.(
									totalSelectedRowIds,
									() => table.toggleAllRowsSelected( false ),
									selectedRows || totalSelectedFlatRows,
								)}
							</Box>
						</Collapse>
					</Stack>
				</Fade>
			</Grid>
			<Grid item xs={6} my={1}>
				<Stack
					direction='row'
					spacing={1}
					sx={{ float: 'right', pb: { xs: 1, sm: 0 } }}>
					{/*{refreshable && !isMobile && (*/}
					{/*	<Tooltip placement='left' title='Refresh The Table'>*/}
					{/*		<IconButton*/}
					{/*			color='primary'*/}
					{/*			onClick={() => event.emit( 'reload.graphqlQuery', true )}>*/}
					{/*			<RefreshIcon/>*/}
					{/*		</IconButton>*/}
					{/*	</Tooltip>*/}
					{/*)}*/}
					{!isMobile && !isEmpty( tableEditableData?.data ) && (
						<Stack direction='row' spacing={1}>
							<QuickEditCancelButton/>
							<QuickEditSaveButton tableSavableData={tableSavableData}/>
						</Stack>
					)}
					{headerExtraActions ? isValidElement( headerExtraActions ) ? (
						<div>{headerExtraActions}</div>
					) : (
						<Box>
							<Actions
								items={( headerExtraActions as ActionType ).items}
								max={( headerExtraActions as ActionType ).max}
								wrapperProps={{ sx: { m: 0 } }}
							/>
						</Box>
					) : null}
					{!isMobile && tableActionMenu && !isCloverDevice && ( alwaysShowActionMenu || Boolean( badgeCount ) ) && (
						<GraphqlTableCSVButton
							tableActionMenu={tableActionMenu.filter( ( action ) => action !== 'showAlways' )}
							variables={variables}
							table={table}
						/>
					)}
					{!( isMobile && showMobileCheckbox ) && (
						<Fragment>
							{useQuickFilters && !isMobile && <QuickFiltersButton useQuickFilters={useQuickFilters}/>}
							{tableSavedFilters && staff && (
								<SavedFiltersButton tableSavedFilters={tableSavedFilters}/>
							)}
							{!hideColumnFilters && !isMobile && (
								<ColumnsButton table={table} hiddenTableColumns={hiddenTableColumns}/>
							)}
							{!isEmpty( filteredColumns ) && (
								<Tooltip title='Apply Filters'>
									<Button
										variant='contained'
										color='primary'
										sx={{ animation: badgeCount ? 'ripple2 1s linear infinite' : undefined }}
										endIcon={Boolean( badgeCount ) && (
											<Stack direction='row' alignItems='center' spacing={1}>
												<Divider orientation='vertical'/>
												<Typography>{badgeCount}</Typography>
											</Stack>
										)}
										startIcon={<FilterListIcon/>}
										onClick={( e ) => showMenu( ( { closeMenu } ) => (
											<Filters
												columns={table.allColumns}
												setAllFilters={table.setAllFilters}
												filters={table.state.filters}
												closeMenu={closeMenu}
											/>
										), e.currentTarget, { MenuListProps: { sx: { p: 0, minWidth: 300 } } } )}>
										{t( 'common:filter' )}
									</Button>
								</Tooltip>
							)}
						</Fragment>
					)}
				</Stack>
			</Grid>
		</Grid>
	);
}
