import { RemoveRounded as RemoveRoundedIcon } from '@mui/icons-material';
import type { CheckboxProps } from '@mui/material';
import { Checkbox, styled } from '@mui/material';

export const Icon = styled( 'span' )( ( { theme } ) => ( {
	'transition'  : '.3s',
	'borderRadius': '5px',
	'width'       : '17px',
	'height'      : '17px',
	'boxShadow'   : theme.palette.mode === 'dark'
		? '0 0 0 1px rgb(16 22 26 / 40%)'
		: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px',
	'backgroundColor': theme.palette.mode === 'dark' ? '#4f4f4f' : '#ffffff',
	// '.Mui-focusVisible &': {
	// 	outline      : '2px auto rgba(19,124,189,.6)',
	// 	outlineOffset: 2,
	// },
	'input:hover ~ &': {
		backgroundColor: theme.palette.mode === 'dark' ? '#4d4d4d' : '#ebf1f5',
	},
	'input:disabled ~ &': {
		height   : '17px',
		width    : '17px',
		boxShadow: 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px',
		background:
			theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
	},
} ) );

export const CheckedIcon = styled( Icon )( {
	'backgroundColor': '#635BFF',
	'transition'     : '.3s',
	'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before'       : {
		display: 'block',
		width  : 17,
		height : 17,
		backgroundImage:
			'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath'
			+ ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 '
			+ '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#635BFF',
		filter         : 'brightness(120%)',
	},
} );

export default function CustomCheckBox( { ...props }: CheckboxProps ) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: 'transparent' },
				'pr'     : 1,
			}}
			color='default'
			icon={<Icon/>}
			checkedIcon={<CheckedIcon/>}
			indeterminateIcon={(
				<RemoveRoundedIcon sx={{
					'borderRadius'   : '4px',
					'width'          : '16px',
					'height'         : '16px',
					'boxShadow'      : 'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px',
					'backgroundColor': '#635BFF',
					'fill'           : '#ffffff',
					':hover'         : {
						backgroundColor: 'red',
						filter         : 'brightness(120%)',
					},
				}}
				/>
			)}
			{...props}
		/>
	);
}
