import { gql } from '@apollo/client';

export const EmployeePublicTableWrite = gql`
	mutation EmployeePublicTableWrite( $id: String, $method: String, $input: EmployeeValidator, $remove: Boolean, $logMethod: String ) {
		employeePublicWrite( id: $id, method: $method, input: $input, remove: $remove, logMethod: $logMethod ) {
			id
			name
			email
			phone
			cardNumber
			createdAt
			updatedAt
		}
	}
`;

export const EmployeesPublicTableWrite = gql`
	mutation EmployeesPublicTableWrite( $inputs: [EmployeeValidator!]!) {
		employeesPublicWrite( inputs: $inputs)
	}
`;

const employeeData = `
	id
	name
	email
	phone
	privateNote
	usedCredit
	dailyLimit
	monthlyLimit
	totalLimit
	spentToDate
	expiryDate
	cardNumber
	active
	image
	absorbValue
	personalId
	houseAccount {
		id
		name
		email
		number
		company {
			id
			name
		}
	}
`;

export const EmployeePublicRead = gql`
	query EmployeePublicRead($id: String) {
		employeePublicRead(id: $id) {
			${employeeData}
		}
	}
`;

export const EmployeesPublicTableRead = gql`
	query EmployeesPublicTableRead($options: FilterOptions) {
		employeesPublicRead(options: $options) {
			items {
				${employeeData}
			}
			count
		}
	}
`;
