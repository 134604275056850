
import type { ActionProps } from '@/components/actions';
import { ActionType } from '@/components/graphqlTable/index';
import { scrollLeftAtom, scrollRightAtom } from '@/hooks/useScroll';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Fragment, MutableRefObject, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TableInstance } from 'react-table';
import CustomCheckBox from '../customCheckBox';

export default function TableHeader( {
	table,
	tableHeaderRef,
	showCheckBox,
	expandedComponent,
	useActions,
	selectedRows,
	setSelectedRows,
	actionCellWidth,
}: {
	table: TableInstance<any>,
	tableHeaderRef: MutableRefObject<HTMLDivElement>,
	showCheckBox: boolean,
	expandedComponent: ( row ) => ReactNode,
	useActions: ( row ) => ActionProps[] | ActionType,
	selectedRows?: any[],
	setSelectedRows?: ( rows: any[] ) => void,
	actionCellWidth?: number
} ) {
	const { t } = useTranslation();
	
	const totalSelectedRowIds = Object.keys( table.state.selectedRowIds );
	
	const totalSelected = totalSelectedRowIds.length;
	const checkBoxState = table.getToggleAllPageRowsSelectedProps();
	const totalSelectedFlatRows = table.selectedFlatRows.map( ( row ) => row.original );
	
	const [ selected, setSelected ] = useState( false );
	
	useEffect( () => {
		if ( selected && setSelectedRows ) {
			const totalSelectedRows = [ ...selectedRows || [], ...totalSelectedFlatRows ];
			const totalCheckedRows = totalSelectedRowIds.map( ( id ) => totalSelectedRows.find( ( row ) => row.id === id ) );
			setSelectedRows?.( totalCheckedRows );
			setSelected( false );
		}
		
	}, [ selected, totalSelectedRowIds, totalSelectedFlatRows ] );
	
	return (
		<TableHead ref={tableHeaderRef} component='div'>
			{table.headerGroups.map( ( headerGroup, idx ) => {
				const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
				return (
					<TableRow
						key={headerGroup.id || key}
						{...headerGroupProps}
						component='div'>
						<Fragment>
							{showCheckBox && (
								<CheckBoxCell
									checkBoxState={checkBoxState}
									totalSelected={totalSelected}
									setSelected={setSelected}
								/>
							)}
							{Boolean( expandedComponent ) && (
								<TableCell component='div' sx={{ pr: 0 }}>
									<IconButton disabled sx={{ opacity: 0 }}>
										<ExpandMoreIcon/>
									</IconButton>
								</TableCell>
							)}
							{headerGroup.headers.map( ( column ) => {
								const { key, ...columnProps } = column.getHeaderProps( column.getSortByToggleProps() );
								return (
									<TableCell
										key={column.id || key}
										{...columnProps}
										component='div'>
										<TableSortLabel
											active={column.isSorted}
											hideSortIcon={!column.canSort}
											direction={column.isSortedDesc ? 'desc' : 'asc'}>
											{typeof column.render( 'Header' ) === 'string'
												? t( column.render( 'Header' ).valueOf() as string )
												: column.render( 'Header' )}
										</TableSortLabel>
									</TableCell>
								);
							} )}
							{Boolean( useActions ) && ( <ActionCell actionCellWidth={actionCellWidth}/> )}
						</Fragment>
					</TableRow>
				);
			} )}
		</TableHead>
	);
}

function CheckBoxCell( { checkBoxState, totalSelected, setSelected } ) {
	const isMoreThan5PercentAwayLeft = useAtomValue( scrollLeftAtom );
	return (
		<TableCell
			sx={{
				width     : '50px',
				position  : 'sticky',
				top       : 0,
				left      : 0,
				zIndex    : 3,
				transition: 'filter 0.3s ease-out',
				filter    : isMoreThan5PercentAwayLeft
					? 'drop-shadow(rgba(0, 0, 0, 0.3) 2px 10px 6px)'
					: 'none',
			}}>
			<CustomCheckBox
				{...checkBoxState}
				sx={{ visibility: checkBoxState.checked || totalSelected < 100 ? 'visible' : 'hidden' }}
				onClick={( e ) => {
					e.stopPropagation();
					setSelected( true );
				}}
			/>
		</TableCell>
	);
}

function ActionCell( { actionCellWidth } ) {
	const { t } = useTranslation();
	const isMoreThan5PercentAwayRight = useAtomValue( scrollRightAtom );
	
	return (
		<TableCell
			align='right'
			component='div'
			sx={{
				width     : actionCellWidth,
				position  : 'sticky',
				right     : 0,
				zIndex    : 3,
				transition: 'filter 0.3s ease-out',
				filter    : isMoreThan5PercentAwayRight
					? 'drop-shadow(rgba(0, 0, 0, 0.3) -2px 10px 6px)'
					: 'none',
			}}>
			<Typography fontWeight={500}>{t( 'common:actions' )}</Typography>
		</TableCell>
	);
}
